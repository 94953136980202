export function getIconClassesBasedOnExtension(pFileName: string): string {
    const   vNameParts = pFileName.split("."), 
            vExtension = vNameParts[vNameParts.length - 1].toLowerCase();

    switch (vExtension) {
        case "pdf":
            return "bi bi-file-earmark-pdf text-danger";
        case "doc":
        case "docx":
            return "bi bi-file-earmark-word text-primary";
        case "txt":
        case "rtf":
            return "bi bi-filetype-txt text-primary";
        case "xls":
        case "xlsx":
            return "bi bi-file-earmark-excel text-success";
        case "ppt":
        case "pptx":
            return "bi bi-file-earmark-ppt text-danger";
        case "jpg":
        case "jpeg":
        case "bmp":
        case "png":
        case "gif":
            return "bi bi-file-earmark-image text-warning";
        case "zip":
        case "rar":
            return "bi bi-file-earmark-zip text-danger";
        default:
            return "bi bi-file-earmark text-primary";
    }
}